<template>
  <b-container
    class="mb-5"
    fluid
  >
    <div class="d-flex justify-content-end mb-3 pb-0">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          sm="9"
        >
          <div class="form-group">
            <label class="font-weight-bolder">
              Search
            </label>
            <b-input
              v-model="tablePending.filter.search"
              type="text"
              placeholder="Search here"
              debounce="1000"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="3"
        >
          <div class="form-group">
            <label class="font-weight-bolder">
              Company
            </label>
            <b-select
              v-model="tablePending.filter.company"
              :options="filterCompanies"
            />
          </div>
        </b-col>
      </b-row>

      <b-table
        ref="tablePending"
        hover
        responsive
        class="mt-2"
        :per-page="tablePending.perPage"
        :current-page="tablePending.currentPage"
        :items="tableProvider"
        :fields="tablePending.fields"
        :sort-by.sync="tablePending.sortBy"
        :sort-desc.sync="tablePending.sortDesc"
        :sort-direction="tablePending.sortDirection"
        :filter="tablePending.filter"
        :filter-included-fields="tablePending.filterOn"
        show-empty
      >

        <template #cell(index)="data">
          {{ tablePending.currentPage * tablePending.perPage - tablePending.perPage + (data.index + 1) }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              variant="primary"
              @click="onProcess(row.item)"
            >
              Process
            </b-button>
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tablePending.perPage"
              :options="tablePending.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tablePending.currentPage"
            :total-rows="tablePending.totalRows"
            :per-page="tablePending.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>

    </b-card>

    <b-modal
      id="modal-maintenance-form"
      size="xl"
      scrollable
      no-close-on-backdrop
      no-enforce-focus
      aria-hidden="false"
      :title="modalTitle"
    >
      <ValidationObserver
        ref="formMaintenance"
      >
        <form
          novalidate
          role="form"
          @submit.prevent
        >
          <b-row>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group>
                <label for="created_at">
                  <strong>Requested At</strong>
                </label>
                <b-input
                  id="created_at"
                  v-model="maintenance.created_at"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group>
                <label for="requestor_name">
                  <strong>Requested By</strong>
                </label>
                <b-input
                  id="requestor_name"
                  v-model="maintenance.requestor_name"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group>
                <label for="company_name">
                  <strong>Company</strong>
                </label>
                <b-input
                  id="company_name"
                  v-model="maintenance.company_name"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group>
                <label for="organization_name">
                  <strong>Organization</strong>
                </label>
                <b-input
                  id="organization_name"
                  v-model="maintenance.organization_name"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group>
                <label for="estimated_amount">
                  <strong>Estimated Amount</strong>
                </label>
                <b-input
                  id="estimated_amount"
                  v-model="maintenance.estimated_amount"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group>
                <label for="funding_type">
                  <strong>Funding Type</strong>
                </label>
                <b-input
                  id="funding_type"
                  v-model="fundingTypeValue"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-row>

                <b-col
                  cols="12"
                  md="3"
                >
                  <b-form-group>
                    <label for="vehicle_license_plate">
                      <strong>Vehicle Plate Number</strong>
                    </label>
                    <b-input
                      id="vehicle_license_plate"
                      v-model="maintenance.vehicle_license_plate"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                >
                  <b-form-group>
                    <label for="vehicle_name">
                      <strong>Vehicle Name</strong>
                    </label>
                    <b-input
                      id="vehicle_name"
                      v-model="maintenance.vehicle_name"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                >
                  <b-form-group>
                    <label for="vehicle_category_name">
                      <strong>Vehicle Category</strong>
                    </label>
                    <b-input
                      id="vehicle_category_name"
                      v-model="maintenance.vehicle_category_name"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                >
                  <b-form-group>
                    <label for="vehicle_type">
                      <strong>Vehicle Type</strong>
                    </label>
                    <b-input
                      id="vehicle_type"
                      v-model="vehicleIsValue"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group>
                    <label for="last_odometer">
                      <strong>Last Odometer</strong>
                    </label>
                    <b-input
                      id="last_odometer"
                      v-model="maintenance.last_odometer"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group>
                    <label for="current_odometer">
                      <strong>Current Odometer</strong>
                    </label>
                    <b-input
                      id="current_odometer"
                      v-model="maintenance.current_odometer"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group>
                <label for="damaged_item_specifications">
                  <strong>Ano ang Nasira?</strong>
                </label>
                <b-textarea
                  id="damaged_item_specifications"
                  v-model="maintenance.damaged_item_specifications"
                  rows="6"
                  max-rows="8"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group>
                <label for="cause_of_damage">
                  <strong>Rason ng Pagkasira?</strong>
                </label>
                <b-textarea
                  id="cause_of_damage"
                  v-model="maintenance.cause_of_damage"
                  rows="6"
                  max-rows="8"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <label>
                <strong>Attachments</strong>
              </label>
              <b-table
                ref="attachment_table"
                class="mt-1"
                :fields="tableAttachments.fields"
                :items="maintenance.attachments"
                responsive
                striped
                hover
              >
                <template #cell(actions)="row">
                  <div class="text-nowrap">
                    <b-button
                      size="sm"
                      class="mr-1"
                      @click="onPreviewAttachment(row.item)"
                    >
                      Preview
                    </b-button>
                  </div>
                </template>
              </b-table>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="remarks"
                vid="noted_by_remarks"
                rules="required"
              >
                <b-form-group>
                  <label for="noted_by_remarks">
                    <strong>Remarks / Rekomendasyon</strong>
                  </label>
                  <b-textarea
                    id="noted_by_remarks"
                    v-model="maintenance.noted_by_remarks"
                    rows="6"
                    max-rows="8"
                    placeholder="sabihin ang iyong rekomendasyon"
                    autocomplete="off"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                    v-text="errors[0]"
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
        </form>
      </ValidationObserver>
      <hr>
      <b-row>
        <b-col cols="12">
          <ValidationObserver
            ref="formItems"
          >
            <form
              novalidate
              role="form"
              @submit.prevent
            >
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <h4>Parte o Materyales na Kelangan</h4>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="item name"
                    vid="item_name"
                    rules="required"
                  >
                    <b-form-group>
                      <label for="item_name">
                        <strong>Item Name</strong>
                      </label>
                      <b-input
                        id="item_name"
                        v-model="item.name"
                        type="text"
                        placeholder="enter name"
                        autocomplete="off"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                      />

                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                        v-text="errors[0]"
                      />
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="item quantity"
                    vid="item_quantity"
                    rules="required|min_value:1"
                  >
                    <b-form-group>
                      <label for="item_quantity">
                        <strong>Item Quantity</strong>
                      </label>
                      <b-input
                        id="item_quantity"
                        v-model="item.quantity"
                        type="number"
                        placeholder="enter quantity"
                        autocomplete="off"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                      />

                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                        v-text="errors[0]"
                      />
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="unit of measurement"
                    vid="unit_of_measurement"
                    rules="required|max:150"
                  >
                    <b-form-group>
                      <label for="unit_of_measurement">
                        <strong>Unit of Measurement</strong>
                      </label>
                      <b-input
                        id="unit_of_measurement"
                        v-model="item.unit_of_measurement"
                        type="text"
                        placeholder="enter unit of measurement"
                        autocomplete="off"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                      />

                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                        v-text="errors[0]"
                      />
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="item last request"
                    vid="last_request"
                    rules=""
                  >
                    <b-form-group>
                      <label for="last_request">
                        <strong>Last Request</strong>
                      </label>
                      <b-input
                        id="last_request"
                        v-model="item.last_request"
                        type="date"
                        placeholder="enter last request"
                        autocomplete="off"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                      />

                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                        v-text="errors[0]"
                      />
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group label="Item from?">
                    <b-form-radio-group
                      id="item_from"
                      v-model="item.item_from"
                      :options="partsFrom"
                      name="item_from"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                  class="mb-1 d-flex align-items-center"
                >
                  <b-button
                    size="sm"
                    class="mr-1"
                    variant="success"
                    @click="onSaveItem"
                  >
                    Save Item
                  </b-button>
                  <b-button
                    size="sm"
                    class="mr-1"
                    type="button"
                    @click="onViewVehicleHistories"
                  >
                    Vehicle Item Histories
                  </b-button>
                </b-col>
              </b-row>
            </form>
          </ValidationObserver>
          <b-table
            ref="items_table"
            class="mt-1"
            :fields="tablePartsNeeded.fields"
            :items="maintenance.maintenance_items"
            responsive
            striped
            hover
          >
            <template #cell(name)="row">
              <div class="text-nowrap">
                {{ row.item.name }}
              </div>
            </template>
            <template #cell(actions)="row">
              <div class="text-nowrap">
                <b-button
                  variant="success"
                  size="sm"
                  class="mr-1"
                  :disabled="state.busy || state.editing"
                  @click="onEditItem(row.item)"
                >
                  Edit
                </b-button>
                <b-button
                  variant="danger"
                  size="sm"
                  :disabled="state.busy || state.editing"
                  @click="onRemoveItem(row.item)"
                >
                  Remove
                </b-button>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <template #modal-footer="{ ok, cancel }">
        <div class="col-12 d-flex justify-content-between">
          <div>
            <b-button
              variant="success"
              class="mr-1"
              :disabled="state.busy"
              @click="onValidate()"
            >
              Approve
            </b-button>
            <b-button
              variant="outline-danger"
              :disabled="state.busy"
              @click="onValidate('Disapproved')"
            >
              Disapprove
            </b-button>
          </div>
          <b-button
            variant="outline-dark"
            :disabled="state.busy"
            @click="cancel()"
          >
            Close
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="modal-vehicle-item-histories"
      size="xl"
      scrollable
      no-close-on-backdrop
      no-enforce-focus
      aria-hidden="false"
      title="Vehicle Item Histories"
    >
      <b-row>
        <b-col
          cols="12"
        >
          <b-form-group>
            <label for="item_name">
              <strong>Search</strong>
            </label>
            <b-input
              id="search_item_name"
              v-model="tableVehicleHistories.filter.search"
              type="text"
              placeholder="search here"
              autocomplete="off"
              :disabled="state.busy"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
        >
          <b-table
            ref="vehicle_histories"
            hover
            responsive
            class="mt-2"
            :per-page="tableVehicleHistories.perPage"
            :current-page="tableVehicleHistories.currentPage"
            :items="tableVehicleHistoriesProvider"
            :fields="tableVehicleHistories.fields"
            :sort-by.sync="tableVehicleHistories.sortBy"
            :sort-desc.sync="tableVehicleHistories.sortDesc"
            :sort-direction="tableVehicleHistories.sortDirection"
            :filter="tableVehicleHistories.filter"
            :filter-included-fields="tableVehicleHistories.filterOn"
            show-empty
          >
            <template #cell(actions)="row">
              <div class="text-nowrap">
                <b-button
                  size="sm"
                  variant="primary"
                  @click="onAddItem(row.item)"
                >
                  Select
                </b-button>
              </div>
            </template>
          </b-table>
        </b-col>
        <b-col
          cols="12"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableVehicleHistories.currentPage"
            :total-rows="tableVehicleHistories.totalRows"
            :per-page="tableVehicleHistories.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="outline-dark"
          @click="cancel()"
        >
          Close Preview
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-preview-image"
      size="lg"
      centered
      scrollable
      no-close-on-backdrop
      no-enforce-focus
      aria-hidden="false"
      :title="`Preview Attachment [${file.name}]`"
    >
      <div class="d-flex justify-content-center">
        <b-img
          fluid
          :src="file.url"
        />
      </div>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="outline-dark"
          @click="cancel()"
        >
          Close Preview
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>
<script>
import { SSMMaintenance, SSharedList, AxiosError } from '@/services'
import { core } from '@/config/pluginInit'
import formatter from '@/mixins/formatter'
import url from '@/mixins/url'
import helper from '@/mixins/helper'

export default {

  name: 'VSMPending',

  middleware: ['auth', 'senior-mechanic'],

  metaIno () {
    return {
      title: 'Pending Approvals'
    }
  },

  mixins: [formatter, url, helper],

  data () {
    return {
      state: {
        busy: false,
        editing: false
      },
      file: {
        name: '',
        url: ''
      },
      list: {
        companies: []
      },
      item: {
        id: 0,
        name: '',
        quantity: 0,
        item_from: 'inventory',
        unit_of_measurement: '',
        last_request: ''
      },
      maintenance: {
        id: 0,
        actual_amount: 0,
        estimated_amount: 0,
        funding_type: 'petty-cash',
        company_name: '',
        organization_name: '',
        requestor_name: '',
        user_name: '',
        vehicle: '',
        vehicle_name: '',
        vehicle_category_name: '',
        vehicle_license_plate: '',
        vehicle_type: 'owned',
        damaged_item_specifications: '',
        cause_of_damage: '',
        last_odometer: 0,
        current_odometer: 0,
        noted_by_remarks: '',
        status: 'Pending',
        noted_by_status: 'Disapproved',
        attachments: [],
        histories: [],
        maintenance_items: [],
        created_at: ''
      },
      tablePending: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          search: '',
          company: 'All'
        },
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, label: 'requested at' },
          { key: 'requestor_name', label: 'Requested By' },
          { key: 'vehicle_license_plate', label: 'License Plate' },
          { key: 'vehicle_category_name', label: 'Vehicle Category' },
          { key: 'status' },
          { key: 'updated_at', formatter: this.dateTimeFormatter, label: 'last updated' }
        ]
      },
      tablePartsNeeded: {
        fields: [
          'id',
          'actions',
          { key: 'name' },
          { key: 'quantity', class: 'text-center' },
          { key: 'unit_of_measurement', label: 'UoM', class: 'text-center' },
          { key: 'item_from', formatter: this.findPartsFrom },
          { key: 'last_request', formatter: this.checkLastRequest }
        ]
      },
      tableAttachments: {
        fields: [
          'actions',
          { key: 'file_original_name', label: 'filename' }
        ]
      },
      tableVehicleHistories: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: 'created_at',
        sortDesc: true,
        sortDirection: 'desc',
        filter: {
          search: ''
        },
        filterOn: [],
        fields: [
          'document_number',
          'actions',
          { key: 'name' },
          { key: 'quantity' },
          { key: 'unit_of_measurement' },
          { key: 'item_from', formatter: this.findPartsFrom },
          { key: 'created_at', formatter: this.dateTimeFormatter, label: 'requested at' }
        ]
      }
    }
  },

  computed: {
    modalTitle () {
      return 'Process Request'
    },

    filterCompanies () {
      return [{ value: 'All', text: 'All' }].concat(this.list.companies)
    },

    fundingTypeValue () {
      return this.findFundingType(this.maintenance.funding_type)
    },

    vehicleIsValue () {
      return this.findVehicleIs(this.maintenance.vehicle_type)
    }
  },

  mounted () {
    core.index()
    this.getOptions()
  },

  methods: {

    async tableProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tablePending.busy = true
      const filters = this.objectToUrl({
        page: currentPage,
        per_page: perPage,
        sort: sortBy,
        sort_desc: sortDesc,
        filter_company: filter.company,
        filter_text: filter.search
      })

      return await SSMMaintenance.get(filters).then(
        ({ data }) => {
          this.tablePending.totalRows = data.total_rows
          return data.items
        }
      )
        .catch(() => {
          this.tablePending.totalRows = 0
          return []
        })
        .finally(() => {
          this.tablePending.busy = false
        })
    },

    async tableVehicleHistoriesProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tableVehicleHistories.busy = true
      const filters = this.objectToUrl({
        page: currentPage,
        per_page: perPage,
        sort: sortBy,
        sort_desc: sortDesc,
        filter_text: filter.search,
        filter_maintenance: this.maintenance.id,
        filter_vehicle: this.maintenance.vehicle
      })

      return await SSMMaintenance.getVehicleHistories(filters).then(
        ({ data }) => {
          this.tableVehicleHistories.totalRows = data.total_rows
          return data.items
        }
      )
        .catch(() => {
          this.tableVehicleHistories.totalRows = 0
          return []
        })
        .finally(() => {
          this.tableVehicleHistories.busy = false
        })
    },

    async getOptions () {
      return await SSharedList.getCompanies().then(
        ({ data }) => {
          this.list.companies = data.map(
            ({ id, company_name }) => ({
              text: company_name,
              value: id
            })
          )
        }
      ).catch(() => {
        this.list.companies = []
      })
    },

    async getLastOdometer (maintenance) {
      const filters = this.objectToUrl({
        vehicle: maintenance.vehicle_id,
        maintenance: maintenance.id
      })

      SSMMaintenance.getLastOdometer(filters).then(
        ({ data }) => {
          this.maintenance.last_odometer = data.last_odometer
        }
      ).catch(() => {
        this.maintenance.last_odometer = null
      })
    },

    async onViewVehicleHistories () {
      this.$bvModal.show('modal-vehicle-item-histories')
    },

    async onProcess (row) {
      this.maintenance.id = row.id
      this.maintenance.estimated_amount = row.estimated_amount
      this.maintenance.funding_type = row.funding_type
      this.maintenance.company_name = row.company_name
      this.maintenance.organization_name = row.organization_name
      this.maintenance.requestor_name = row.requestor_name
      this.maintenance.user_name = row.user_name
      this.maintenance.vehicle = row.vehicle_id
      this.maintenance.vehicle_name = row.vehicle_name
      this.maintenance.vehicle_category_name = row.vehicle_category_name
      this.maintenance.vehicle_license_plate = row.vehicle_license_plate
      this.maintenance.vehicle_type = row.vehicle_type
      this.maintenance.damaged_item_specifications = row.damaged_item_specifications
      this.maintenance.cause_of_damage = row.cause_of_damage
      this.maintenance.attachments_path = row.attachments_path
      this.maintenance.attachments = row.attachments
      this.maintenance.status = row.status
      this.maintenance.maintenance_items = []
      this.maintenance.last_odometer = row.last_odometer
      this.maintenance.current_odometer = row.current_odometer
      this.maintenance.noted_by_remarks = ''

      this.maintenance.created_at = this.dateTimeFormatter(row.created_at)

      // this.getLastOdometer(row)

      this.$bvModal.show('modal-maintenance-form')
    },

    async onValidate (notedByStatus = 'Approved') {
      await this.$refs.formMaintenance.validate().then(
        async areAllFieldsValid => {
          const isApproved = notedByStatus === 'Approved'
          const forApproved = isApproved && this.maintenance.maintenance_items.length >= 1
          const forDisapproved = !isApproved && this.maintenance.maintenance_items.length <= 0

          if (areAllFieldsValid && (forApproved || forDisapproved)) {
            this.maintenance.noted_by_status = notedByStatus

            this.$swal.fire({
              icon: 'question',
              title: 'Confirmation',
              text: isApproved ? 'Approve this Repair/Maintenance Request?' : 'Disapprove this Repair/Maintenance Request?',
              confirmButtonColor: isApproved ? '#06C270' : '#FF2929',
              confirmButtonText: isApproved ? 'Approve' : 'Disapprove',
              cancelButtonColor: '#737373',
              showCancelButton: true,
              showLoaderOnConfirm: true,
              preConfirm: async () => {
                this.state.busy = true
                return this.onPut()
              },
              allowOutsideClick: () => !this.$swal.isLoading()
            })
          } else {
            if (isApproved && this.maintenance.maintenance_items.length <= 0) {
              this.$refs.formItems.setErrors({
                item_name: ['The maintenance items must be atleast greater than or equal to 1.']
              })
            }

            this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
              title: 'Validation Error',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              centered: true
            })
          }
        }
      )
    },

    async onPut () {
      return new Promise(
        resolve => {
          SSMMaintenance.put(this.maintenance).then(
            ({ data }) => {
              this.$bvModal.hide('modal-maintenance-form')
              this.$swal.fire({
                icon: 'success',
                title: 'Successful',
                text: data.message,
                confirmButtonColor: '#06C270',
                confirmButtonText: 'Dismiss'
              }).then(() => {
                this.$refs.tablePending.refresh()
              })
            }
          ).catch(
            error => {
              if (error instanceof AxiosError) {
                if (error.code === 422) {
                  this.$refs.formMaintenance.setErrors(error.message)
                }
              }
            }
          ).finally(resolve)
        }
      ).then(() => {
        this.state.busy = false
      })
    },

    findItemByIndex (item) {
      return this.maintenance.maintenance_items.findIndex(
        row => item.id === row.id
      )
    },

    async onClearItem () {
      this.item = {
        id: 0,
        name: '',
        quantity: 0,
        item_from: 'inventory',
        unit_of_measurement: '',
        last_request: ''
      }

      this.$refs.formItems.reset()
    },

    async onAddItem (row) {
      this.item = {
        id: 0,
        name: row.name,
        quantity: 0,
        item_from: 'inventory',
        unit_of_measurement: row.unit_of_measurement,
        last_request: this.dateFormatter(row.created_at)
      }

      this.$bvModal.hide('modal-vehicle-item-histories')
    },

    async onEditItem (row) {
      this.state.editing = true
      this.item = row
    },

    async onRemoveItem (row) {
      this.maintenance.maintenance_items = this.maintenance.maintenance_items.filter(
        filter => filter.id !== row.id
      )
    },

    async onSaveItem (btnEvt) {
      btnEvt.preventDefault()
      await this.$refs.formItems.validate().then(
        async areAllFieldsValid => {
          if (areAllFieldsValid) {
            const id = this.maintenance.maintenance_items.length + 1

            if (this.item.id === 0) {
              this.maintenance.maintenance_items.push({
                ...this.item,
                id: id
              })
            } else {
              const index = this.findItemByIndex(this.item)
              if (index >= 0) {
                this.maintenance.maintenance_items[index] = this.item
              }
            }

            this.onClearItem()
          } else {
            this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
              title: 'Validation Error',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              centered: true
            })
          }

          this.state.editing = false
        }
      )
    },

    async onPreviewAttachment (file) {
      this.file.name = file.file_original_name
      this.file.url = this.getImageUrl(`${this.maintenance.attachments_path}/${file.file_hash_name}`)
      this.$bvModal.show('modal-preview-image')
    }

  }

}
</script>
<style scoped>
  .w-200px {
    width: 200px;
  }
</style>
